body {
  /*background: #232526;  /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to bottom, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(to bottom, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  /*text-align: center;*/
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  /*font-family: 'Ubuntu', sans-serif;*/
}

* {
  padding: 0;
  margin: 0;
}

#root, .App {
  height: 100%;
}

.break-out {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

/* NOTES */

.notesHeader, .notesBody, .notesFooter {
  /*	background-color: rgba(0, 0, 0, .5);*/
  padding: 20px;
  margin: 0;
}

.notesHeader {
  /*font-family: Trebuchet MS,Liberation Sans,DejaVu Sans,sans-serif;*/
}

.notesBody {
  margin-top: 20px;
  height: 100%;
}

.notesContainer {
  background: #360033;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.notesFooter {
  bottom: 0;
  position: fixed;
  left: 0;
  width: 100%;
}

.tituloPagina {
  font-family: 'Special Elite', cursive;
}

/* NIGHT MODE */

.nightMode {
  background: #360033;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100%;
  /*background-color: #121212;
  /*min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);*/
  /*color: #fcfcfc;*/
  color: #fcfcfc;
}

.lightMode {
  background-color: #fcfcfc;
  min-height: 600px;
  /*min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);*/
  color: #222;
}

/* .lightMode img{display:none !important; }
.lightMode .bg-warning{background-color: #ccc !important;}
.lightMode h1{display:none !important;}
.lightMode h2{display:none !important;}
.lightMode .modoOculto{display: none;}
.lightMode .embed-responsive-item{display: none !important;}
.lightMode input.react-autosuggest__input{margin-top:4px;}
.lightMode #twitter-widget-0{display: none !important;} */

.lightMode .nav-tabs .nav-link {
  color: #495057;
}
.lightMode .accordion button {border: 1px solid rgba(0,0,0,.125)}
.lightMode .accordion button p{
  color: #495057
}

/*TEST horizontal scroll*/

.contenedor-scroll {
  display: flex;
  overflow: auto;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  .card {
    display: inline-block;
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}

.card {
  /*border: 2px solid $red;
  width: 350px;
  height: 75px;*/
  background-color: transparent !important;
  border: none;
}

.card-body {
  padding: 20px !important;
}

.imagenBoton {
  float: left;
  width: 10%;
}

.scrolling-wrapper, .scrolling-wrapper-flexbox {
  height: 80px;
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

/*********************/

.default {
  background: #360033;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0b8793, #360033);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0b8793, #360033);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 600px;
  /*background-color: #111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);*/
  color: #fcfcfc;
}

.buttonLightNight {
  position: fixed;
  top: 17px;
  right: 1px;
  z-index: 99999;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.react-autosuggest__suggestions-list a {
  color: #222 !important;
}

.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('//cdnjs.cloudflare.com/ajax/libs/timelinejs/2.25/css/loading.gif') 50% 50% no-repeat rgb(255, 255, 255);
  opacity: 0.5;
}

#selectSearch {
  margin-top: 2px;
}

.react-autosuggest__input {
  width: 100%;
  margin-top: 10px;
  height: 38px;
  border: 1px solid #ccc;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  color: #808080;
  padding-left: 10px;
}

.react-autosuggest__suggestions-container--open {
  width: 100%;
}

.searchResult-name, #selectGenres, #selectYear {
  font-family: Poppins, sans-serif;
  color: #808080;
}

.item-top a {
  color: #ffc107
}

.item-top span.badge a {
  color: #4183c4;
}

.nav-tabs {
  border-bottom: none;
  margin-top: 10px;
}

.tab-content {
  border: 1px solid #dee2e6;
}

#pills-tabContent {
  border: 1px solid white;
}

.w-15 {
  width: 15%;
}

.w-85 {
  width: 85%;
}

.nav-tabs .nav-link {
  color: white;
}

.image-container {
  position: relative;
}

.image-container img:hover {
  opacity: 0.4;
}

.image-container .marco-inferior {
  position: absolute;
  top: 225px;
  width: 100%;
  right: 0px;
  opacity: 0.6;
}

.badge-popular {
  top: 10px;
  right: 10px;
  position: absolute;
}

.badge-estreno {
  top: 10px;
  left: 10px;
  position: absolute;
}

.contenedor-pelicula {
  padding-left: 0px;
  padding-right: 0px;
}

.contenedor-poster {
  margin-left: 15px;
  margin-right: 15px;
}

.titulo-pelicula {
  width: 100%;
  height: 55px;
  /*margin-left:15px;
  margin-right:15px;*/
  /*margin-left:15px; 
  margin-right:15px; */
}

.nav-pills .nav-link.active {
  background: #fff;
  border-bottom: 3px solid blue;
  border-radius: 0px;
}

.navbar {
  padding: 1rem 3rem;
}

.navbar-nav{
  margin-top: 15px;
  display:flex !important;
  width: auto;
  justify-content: flex-start;
  font-size: 20px;
}

.navbar-brand{
  vertical-align: top;
  display: inline-block;
}
.logo-texto{
display: block;
font-weight: 800;
font-size: 10px;
color: #ffc107;
}


.navbar-dark .navbar-nav .nav-link{
  justify-content: flex-start !important;
  color: #ffc107 !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  margin-left: 12px;
}


.underline {
  border-bottom-width: 4px;
  height: 4px;
  border-color: rgba(206, 107, 1, 1);
 }

.navbar-nav .login-link.nav-link{
  color: grey!important;
  justify-content: flex-end !important;
  width: auto;
  margin-left:auto;
}
.navbar-nav .nav-link.logout,
.navbar-nav .nav-link.admin{
  color: grey !important;
}
.navbar-nav .nav-link.account{
  padding-top:0px;
  color: grey !important;
}


.navbar-nav .nav-link.account .nombre-usuario-logado{
  color: grey !important;
  padding-top: 7px;
  display: inline-block;
}

.navbar-nav .nav-link.account img.mr-auto.rounded-circle.responsive-img{
  vertical-align: middle;
}

.movies-genre{
  padding-right: 3px;
  padding-left: 3px;
}
ul#pills-tab{
  background: white;
}
li.nav-item{
  background: white;
}
a.nav-link{
  min-height: 45px;
}
a.nav-link:hover,
a.nav-link.active{
  border-bottom: 4px solid #ffc107;

}
a.nav-link img{
  vertical-align: -webkit-baseline-middle;
}
table.table{
  margin-bottom: 0px;
  /*background-color: #fff;
  color: grey;*/
}
.network-poster{
  padding-left: 3px;
    padding-right: 3px;
    margin-top: 3px;
}
.tab-tvnetwork .row{
  margin-left:0px;
  margin-right: 0px;
}
.posicion{
  font-size: 35px;
    font-weight: 900;
    margin-right: 10px;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.emitiendo{
  width: 20px;
}

.dot {
  width: 1rem;
  height: 1rem;
  background-color: red;
  border-radius: 50%;
  display: block;
  float:left;
}

/* Minimal example */
.dot--basic {
  animation: blink 2s infinite;
}

/* Run animation once */
.dot--once {
  animation: blink 2s 1;
}

/* Wait 4s before running the animation */
.dot--delayed {
  animation: blink 2s infinite 4s;
}

.link-warning{color:#ffc107}