.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 95%;
  height: 35px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.searchResult-text {
  line-height: 21px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.searchResult-image {
    width: 40px;
    height: 50px;
    float: left;
    margin-right: 8px;
}

.searchResult-name {
    font-weight: bold;
}

.searchResult-link {
  display: block;
  padding: 5px;
}

/*.search-container {
  margin: 30px 0;
  color: grey;
  background: black;
}

// sm up
@include media-breakpoint-up(sm) {
  .search-container {
    margin-top: 8px;
  }
}*/
