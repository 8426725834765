

#container {


  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

#flex-scroll {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.box {

}
