.NoteForm input[type="text"]{
	padding: 20px;
}

.NoteForm button {
	/*padding: 22px;*/
	color:#fff;
	background: #019875;
	/*border: none;*/
}

.NoteForm button:hover {
	background-color: #02bab5;
	color:#fff;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}
