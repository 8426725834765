.Note {
	background: none;
	padding: 20px;
	color: #000;
	margin-top: 10px;
	text-align: left;
}
.notesBody .row{
	background-color: none;
}



p.card-text{ margin:10px; }

.box {

}
.green {
  background-color: green;
}
